@import "../../sassStyles/variables";

.hero {
  width: 100%;
  min-height: 100vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  place-items: center;
  color: white;

  .hero-content {
    padding: 0 0.5rem;
    position: absolute;
    width: 100%;
    max-width: 200rem;
    min-height: 30%;
    display: flex;
    flex-direction: column;
  }

  .hero-header {
    font-family: $italiana;
    font-size: clamp(2.2rem, 7vw, 12rem);
    margin-bottom: clamp(10rem, 7vw, 16rem);
    text-align: center;
    letter-spacing: 0.1rem;

    .header-main {
      display: block;
      margin-bottom: 1rem;
    }

    .header-sub {
      display: block;
      color: gold;
    }
  }

  .callout-box {
    position: relative;
    margin: 0 auto;
    border: 2px groove gold;
    padding: clamp(3rem, 2vw, 6rem) 1rem;
    width: 100%;
    max-width: 60rem;
    display: block;
    font-size: clamp(1.6rem, 2.5vw, 2rem);
    text-align: center;
    letter-spacing: 0.1rem;
    color: white;

    span {
      display: block;
    }
  }
}

@media (min-width: 425px) {
  .hero {
    .hero-header {
      text-align: left;
    }
  }
}
