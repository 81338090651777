@import "../../sassStyles/variables";

.navbar {
  width: 100%;
  height: 6rem;
  background-color: white;
  position: fixed;
  z-index: 1;
  border-bottom: 1px solid $grayscale-80;

  .navbar-container {
    max-width: 200rem;
    height: inherit;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo-container {
    border-left: 1px solid $grayscale-80;
    border-right: 1px solid $grayscale-80;
    background-image: url("../../assets/images/JB.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    width: 6rem;
    height: inherit;
    color: transparent;
  }

  .navbar-links-container {
    width: auto;
    height: inherit;
    display: flex;
    align-items: stretch;

    .navbar-link {
      padding: 0 1.2rem;
      font-weight: 700;
      letter-spacing: 1px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.4rem;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        bottom: 0px;
        left: 0;
        right: 0;
        margin: auto;
        width: 0%;
        height: 0.3rem;
      }

      &:hover::after {
        transition: all 0.25s ease-in;
        -webkit-transition: all 0.25s ease-in;
        -moz-transition: all 0.25s ease-in;
        -ms-transition: all 0.25s ease-in;
        -o-transition: all 0.25s ease-in;
        width: 100%;
        background-color: $mahogany;
      }

      &:hover {
        transition: color 0.25s ease-in;
        color: $mahogany;
        -webkit-transition: color 0.25s ease-in;
        -moz-transition: color 0.25s ease-in;
        -ms-transition: color 0.25s ease-in;
        -o-transition: color 0.25s ease-in;
      }
    }
  }
}
