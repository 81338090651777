$nav-border-color: #e7e7e7;
$mahogany: #640a0a;
$grayscale-97: #f7f7f7;
$grayscale-90: #e5e5e5;
$grayscale-80: #ccc;
$grayscale-70: #b3b3b3;
$grayscale-60: #999;
$grayscale-50: #808080;
$grayscale-40: #666;
$grayscale-30: #4d4d4d;
$grayscale-20: #333;
$grayscale-10: #191919;

// Typography

$italiana: "Italiana", serif;
