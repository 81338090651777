@import "../../sassStyles/variables";

.container {
  width: 100%;
  max-width: 144rem;
  min-height: 100vh;
  margin: 0 auto;
  padding: 8rem 0.5rem;

  .section-header {
    text-align: center;
    text-transform: uppercase;
    font-family: $italiana;
    font-size: clamp(1.9rem, 5vw, 6rem);
    letter-spacing: 0.3rem;
    color: $mahogany;
    margin-bottom: 4rem;
  }

  .section-content {
    font-size: 1.6rem;
  }
}
