@import "../../sassStyles/variables";

.project-card {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin-bottom: clamp(3rem, 5vw, 12rem);

  .card-image {
    width: 100%;
    flex: 2 1 30rem;
    overflow: hidden;
    box-shadow: 0px 1rem 1.3rem -0.7rem #000000,
      0.5rem 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0);
    -webkit-box-shadow: 0px 1rem 1.3rem -0.7rem #000000,
      0.5rem 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0);
    transition: transform 0.3s ease-in;
    -webkit-transition: transform 0.3s ease-in;
    -moz-transition: transform 0.3s ease-in;
    -ms-transition: transform 0.3s ease-in;
    -o-transition: transform 0.3s ease-in;

    &:hover {
      transform: scale(0.95);
      -webkit-transform: scale(0.95);
      -moz-transform: scale(0.95);
      -ms-transform: scale(0.95);
      -o-transform: scale(0.95);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.3s ease-in;
      -webkit-transition: transform 0.3s ease-in;
      -moz-transition: transform 0.3s ease-in;
      -ms-transition: transform 0.3s ease-in;
      -o-transition: transform 0.3s ease-in;

      &:hover {
        transform: scale(1.1);
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -ms-transform: scale(1.1);
        -o-transform: scale(1.1);
      }
    }
  }

  .card-content {
    width: 100%;
    height: auto;
    flex: 1 1 30rem;
    display: flex;
    justify-content: center;

    .card-meta {
      font-size: clamp(2.2rem, 5vw, 7rem);
      padding-right: 1rem;
      font-weight: 600;
      border-bottom-style: none;

      span {
        display: inline-block;
        transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
      }
    }

    .card-description {
      .description-title {
        font-family: $italiana;
        font-size: clamp(1.9rem, 5vw, 5rem);
        letter-spacing: 0.3rem;
        margin-bottom: clamp(1rem, 2.5vw, 1.8rem);
      }

      .description-content {
        margin-bottom: clamp(1rem, 2.5vw, 1.8rem);
      }
    }
  }
}

.product-card:last-child {
  margin-bottom: 0;
}

@media (min-width: 630px) {
  .project-card:nth-of-type(even) {
    .card-content {
      order: -1;
    }
  }
}

@media (min-width: 1024px) {
  .card-content {
    flex-direction: column;

    .card-meta {
      margin-bottom: 2rem;

      span {
        margin-bottom: 4rem;
      }
    }
  }
}
