@import "../../sassStyles/variables";

.footer-container {
  border-top: 1px solid $grayscale-80;
  margin: 0 auto;
  width: 100%;
  height: 8rem;
  background-color: $grayscale-60;
  display: grid;
  place-items: center;

  .contact-email {
    color: white;
    font-size: 1.6rem;
    transition: color 0.2s ease-in;
    -webkit-transition: color 0.2s ease-in;
    -moz-transition: color 0.2s ease-in;
    -ms-transition: color 0.2s ease-in;
    -o-transition: color 0.2s ease-in;

    &:hover {
      color: gold;
    }
  }
}
