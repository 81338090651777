@import "../../sassStyles/variables";

#about {
  padding: clamp(2rem, 5vw, 4rem);
}
.about-container {
  width: 100%;
  min-height: 100vh;
  background-color: $grayscale-90;
  margin-top: 2rem;
  display: grid;
  place-items: center;
  position: relative;

  .about-content {
    width: 100%;
    max-width: 102.4rem;
  }

  .about-header {
    text-align: center;
    text-transform: uppercase;
    font-family: $italiana;
    font-size: clamp(4rem, 5vw, 9rem);
    letter-spacing: clamp(0.1rem, 2vw, 2rem);
    color: #e84118;
    margin-bottom: 4rem;
  }

  .about-description {
    font-size: clamp(2rem, 2.5vw, 3.5rem);
    text-align: center;
    color: $grayscale-60;
    font-weight: 200;
    padding: 0 2rem;
  }

  .bottom-banner {
    padding: 0;
    margin: 0;
    position: absolute;
    bottom: 0%;
    transform: translate(0, 27%);
    font-family: $italiana;
    font-size: clamp(4rem, 10vw, 50rem);
    letter-spacing: clamp(0.1rem, 2.5vw, 5rem);
    color: white;
    text-transform: uppercase;
    -webkit-transform: translate(0, 27%);
    -moz-transform: translate(0, 27%);
    -ms-transform: translate(0, 27%);
    -o-transform: translate(0, 27%);
  }
}
